import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['menu']

  clickOutside(event) {
    if (this.element.contains(event.target)) {
      return;
    }

    this.closeMenu();
  }

  closeMenu() {
    this.menuTarget.classList.add('hidden');
  }

  toggleMenu() {
    this.menuTarget.classList.toggle('hidden');
  }
}
import { Controller } from 'stimulus';

export default class extends Controller {
  handleKeyup(event) {
    if (event.code == 'Escape') {
      this.close();
    }
  }

  close(event) {
    // Reset the turbo frame so subsequent
    // clicks will show the modal again.
    // Do this before removing the element!
    this.element.closest('turbo-frame').src = undefined;
    this.element.remove();
  }
}

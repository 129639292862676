import { Controller } from 'stimulus';
import { post } from '@rails/request.js'

export default class extends Controller {
  static values = { url: String }

  change() {
    post(this.urlValue, {
      body: new FormData(this.element),
      responseKind: 'turbo-stream'
    });
  }
}
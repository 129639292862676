import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['button']

  change(event) {
    const value = event.target.value;
    const isValid = value.length > 0;

    this.buttonTarget.disabled = !isValid;
  }
}
import { Controller } from 'stimulus';
import dropin from 'braintree-web-drop-in';

export default class extends Controller {
  static targets = ['nonce', 'submit'];

  static values = {
    token: String,
    isTestEnv: Boolean
  };

  connect() {
    if (this.isTestEnvValue === true) {
      return;
    }

    dropin.create({
      authorization: this.tokenValue,
      container: '#dropin-container'
    }).then((dropinInstance) => {
      this.element.addEventListener('submit', (event) => {
        event.preventDefault();
        $(this.submitTarget).attr('disabled', true);

        dropinInstance.requestPaymentMethod()
          .then((payload) => {
            this.nonceTarget.value = payload.nonce;
            this.element.submit();
          })
          .catch(() => {
            $(this.submitTarget).attr('disabled', false);
          });
      });
    });
  }
}
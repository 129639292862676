import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['form']

  connect() {
    let initialState = this.serializeInputs();

    $(this.formTarget).submit(() => {
      initialState = this.serializeInputs();
    });

    $(window).bind('beforeunload', (e) => {
      const finalState = this.serializeInputs();

      if(initialState != finalState){
        const message = 'You have unsaved changes on this page.';
        return e.returnValue = message;
      }
    });
  }

  serializeInputs() {
    return $('form')
      .find('input')
      .not('[name="authenticity_token"]')
      .serialize();
  }
}
import { Controller } from 'stimulus';

export default class extends Controller {
  connect() {
    this.element.addEventListener('turbo:frame-render', this.changeUrl);
  }

  disconnect() {
    this.element.removeEventListener('turbo:frame-render', this.changeUrl);
  }

  changeUrl(event) {
    const url = event.detail.fetchResponse.response.url;
    history.pushState(null, '', url);
  }
}
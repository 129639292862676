import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['control', 'element'];
  static values = { trigger: String };

  connect() {
    this.setVisibility();
  }

  change() {
    this.setVisibility();
  }

  setVisibility() {
    if (this.controlTarget.value === this.triggerValue) {
      $(this.elementTarget).show();
    } else {
      $(this.elementTarget).hide();
    }
  }
}
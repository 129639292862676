import { Controller } from 'stimulus';

export default class extends Controller {
  handleKeyup(event) {
    if (event.code == 'Escape') {
      this.hide();
    }
  }

  hide() {
    $(this.element).addClass('hidden');
  }
}

import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['button', 'input']
  static values = { match: String }

  checkName(event) {
    const isMatch = event.target.value === this.matchValue;

    this.buttonTarget.disabled = isMatch ? false : true;
  }
}
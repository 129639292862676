import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['control', 'field'];
  static values = { show: Boolean }

  connect() {
    if (!this.hasControlTarget) {
      return;
    }

    this.setVisibility();
  }

  setVisibility() {
    if ($(this.controlTarget).is(':checked') === this.showValue) {
      this.show();
      return;
    }

    this.hide();
  }

  show() {
    $(this.fieldTarget).show();
  }

  hide() {
    $(this.fieldTarget).hide();
  }
}
import { Controller } from 'stimulus';

export default class extends Controller {
  connect() {
    this.element.addEventListener('click', this.changeUrl);
  }

  disconnect() {
    this.element.removeEventListener('click', this.changeUrl);
  }

  changeUrl(event) {
    if (event.target.tagName.toUpperCase() != 'INPUT') {
      window.open(this.dataset.path, '_self', false);
    }
  }
}

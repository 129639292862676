import { Controller } from 'stimulus';
import { get } from "@rails/request.js"

export default class extends Controller {
  static targets = ['receiver'];

  static values = {
    url: String,
    param: String
  }

  change(event) {
    const params = new URLSearchParams()
    params.append(this.paramValue, event.target.value)
    params.append('target', this.receiverTarget.id)

    get(`${this.urlValue}?${params}`, {
      responseKind: 'turbo-stream'
    })
  }
}
import { Controller } from 'stimulus';
import { get } from "@rails/request.js"

export default class extends Controller {
  static targets = [
    'campus',
    'gender',
    'serviceTime',
    'grade',
    'receiver'
  ];

  static values = {
    url: String
  }

  change() {
    this.populateReceiver();
  }

  populateReceiver() {
    const params = new URLSearchParams();

    if (this.campusId) {
      params.append('campus_id', this.campusId);
    }

    if (this.gender) {
      params.append('gender', this.gender);
    }

    if (this.grade) {
      params.append('grade', this.grade);
    }

    if (this.serviceTime) {
      params.append('service_time', this.serviceTime);
    }

    params.append('target', this.receiverTarget.id);

    get(`${this.urlValue}?${params}`, {
      responseKind: 'turbo-stream'
    });
  }

  get campusId() {
    if (!this.hasCampusTarget) {
      return null;
    }

    return this.campusTarget.value;
  }

  get gender() {
    if (!this.hasGenderTarget) {
      return null;
    }

    const checked = $(`[name="${this.genderTarget.name}"]:checked`);
    return checked.length > 0 ? checked.val() : null
  }

  get grade() {
    if (!this.hasGradeTarget) {
      return null;
    }

    return this.gradeTarget.value;
  }

  get serviceTime() {
    if (!this.hasServiceTimeTarget) {
      return null;
    }

    return this.serviceTimeTarget.value;
  }
}
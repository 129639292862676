import { Controller } from 'stimulus';

export default class extends Controller {
  connect() {
    if (!window.scrollLeftPositions) {
      window.scrollLeftPositions = {};
    }

    this.element.addEventListener('scroll', this.storePosition.bind(this));
    addEventListener('turbo:frame-load', this.setPosition.bind(this));
  }

  disconnect() {
    this.element.removeEventListener('scroll', this.storePosition);
    removeEventListener('turbo:frame-load', this.setPosition);
  }

  storePosition() {
    window.scrollLeftPositions[this.element.id] = $(this.element).scrollLeft();
  }

  setPosition() {
    this.element.scrollLeft = window.scrollLeftPositions[this.element.id];
  }
}
import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['template', 'target', 'counter', 'remove', 'add'];
  static values = { max: Number }

  connect() {
    this.renderCount();
    this.renderRemove();
    this.renderAdd();
  }

  add(event) {
    event.preventDefault();

    const content = this.setTemplateIndex(this.templateTarget.innerHTML);
    this.targetTarget.insertAdjacentHTML('beforebegin', content);

    this.renderCount();
    this.renderRemove();
    this.renderAdd();
  }

  setTemplateIndex(templateHtml) {
    return templateHtml.replace(/NEW_RECORD/g, new Date().getTime().toString());
  }

  copyMedicalAndInsurance(event) {
    event.preventDefault();

    var section = $(event.target).parent().parent().parent();

    this.copyFirstValueToTarget(section, 'emergency_contact_first_name');
    this.copyFirstValueToTarget(section, 'emergency_contact_last_name');
    this.copyFirstValueToTarget(section, 'emergency_contact_phone');
    this.copyFirstValueToTarget(section, 'emergency_contact_relationship');

    if ($('[id$=no_insurance]').first().prop('checked'))
    {
      section.find('[id$=no_insurance]').click(); // trigger the attached event
      return;
    }

    this.copyFirstValueToTarget(section, 'carrier_name');
    this.copyFirstValueToTarget(section, 'carrier_phone');
    this.copyFirstValueToTarget(section, 'policy_number');
    this.copyFirstValueToTarget(section, 'policy_number_confirmation');

    this.copyFirstCheckedPropToTarget(section, 'policy_holder_title_mr');
    this.copyFirstCheckedPropToTarget(section, 'policy_holder_title_ms');
    this.copyFirstCheckedPropToTarget(section, 'policy_holder_title_miss');

    this.copyFirstValueToTarget(section, 'policy_holder_first_name');
    this.copyFirstValueToTarget(section, 'policy_holder_last_name');

    this.copyFirstValueToTarget(section, 'policy_holder_employer');
    this.copyFirstValueToTarget(section, 'policy_holder_employer_street_1');
    this.copyFirstValueToTarget(section, 'policy_holder_employer_street_2');
    this.copyFirstValueToTarget(section, 'policy_holder_employer_city');
    this.copyFirstValueToTarget(section, 'policy_holder_employer_state');
    this.copyFirstValueToTarget(section, 'policy_holder_employer_zip');
  }

  copyFirstValueToTarget(targetSection, idEndsWith) {
    targetSection.find(`[id$=${idEndsWith}]`).val(
      $(`[id$=${idEndsWith}]`).first().val()
    );
  }

  copyFirstCheckedPropToTarget(targetSection, idEndsWith) {
    targetSection.find(`[id$=${idEndsWith}]`).prop('checked',
      $(`[id$=${idEndsWith}]`).first().prop('checked')
    );
  }

  remove(event) {
    event.preventDefault();

    const wrapper = event.target.closest('.nested-form-wrapper');

    if (wrapper.dataset.newRecord === 'true') {
      wrapper.remove()
    } else {
      wrapper.style.display = 'none'

      const input = wrapper.querySelector("input[name*='_destroy']")
      input.value = '1'
    }

    this.renderCount();
    this.renderRemove();
    this.renderAdd();
  }

  renderAdd() {
    if (this.count < this.maxValue) {
      $(this.addTarget).show();
      return;
    }

    $(this.addTarget).hide();
  }

  renderCount() {
    $(this.counterTargets).filter(':visible').each(function(index, el) {
      $(el).html(index + 1);
    });
  }

  renderRemove() {
    if (this.count > 1) {
      $(this.removeTargets).show();
      return;
    }

    $(this.removeTargets).hide();
  }

  get count() {
    return $(this.element).find('.nested-form-wrapper').filter(':visible').length;
  }
}
import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['control', 'field'];
  static values = { show: String }

  connect() {
    this.setVisibility()
  }

  setVisibility() {
    if ($(`[name="${this.controlTarget.name}"]:checked`).val() === this.showValue) {
      this.show();
      return;
    }

    this.hide();
  }

  show() {
    $(this.fieldTarget).show();
  }

  hide() {
    $(this.fieldTarget).hide();
  }
}
import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['success']
  static values = { url: String }

  copy() {
    navigator.clipboard.writeText(this.urlValue).then(() => {
      this.successTarget.classList.remove('hidden');

      setTimeout(() => {
        this.successTarget.classList.add('hidden')
      }, 2000);
    });
  }
}

import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['input'];

  static values = {
    height: { type: Number, default: 400 }
  };

  connect() {
    tinymce.init({
      target: this.inputTarget,
      plugins: 'paste lists',
      menubar: false,
      toolbar: 'bold italic bullist numlist',
      branding: false,
      statusbar: true,
      height: this.heightValue,
      content_style: '* { font-size: 14px; }',
      paste_auto_cleanup_on_paste : true,
      convert_urls: false
    });
  }

  disconnect() {
    tinymce.remove();
  }

  insertText(e) {
    const copyContent = $(e.target).siblings('.hidden.content').html();
    tinymce.activeEditor.execCommand('mceInsertContent', false, copyContent);
  }
}

"use strict";
import { Controller } from 'stimulus';

String.prototype.contains = function (str) { return this.indexOf(str) >= 0; };

export default class extends Controller {
  static targets = [ 'campus', 'item', 'query', 'status' ]

  apply_filter(event) {
    var query = $(this.queryTarget).val().trim().toUpperCase();
    var selected_campus = $(this.campusTarget).val().trim();
    var selected_status = $(this.statusTarget).val().trim();

    $(this.itemTargets).each((i, link) => {
      var $link = $(link);

      $link.toggle(
        (query == '' || this.hasSearchText($link, query))
        && (selected_campus == '' || this.hasCampus($link, selected_campus))
        && (selected_status == '' || this.hasStatus($link, selected_status))
      );
    });
  }

  hasSearchText($link, query) {
    var title = $link.find('.special_registration_title').text().trim().toUpperCase();
    return title.contains(query);
  }

  hasCampus($link, selected_campus) {
    var campus_list = $link.find('.special_registration_campus').val().trim();
    return campus_list.contains(selected_campus);
  }

  hasStatus($link, selected_status) {
    var status = $link.find('.special_registration_status').text().trim();
    return status == selected_status;
  }
}
import { Controller } from 'stimulus';
import { get } from "@rails/request.js"

export default class extends Controller {
  static targets = [
    'campus',
    'receiver',
  ];

  static values = {
    url: String
  }

  change() {
    this.populateReceiver();
  }

  populateReceiver() {
    const params = new URLSearchParams();

    params.append('campus_id', this.campusId);
    params.append('target', this.receiverTarget.id);

    get(`${this.urlValue}?${params}`, {
      responseKind: 'turbo-stream'
    });
  }

  get campusId() {
    return this.campusTarget.value;
  }
}